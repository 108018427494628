import React from 'react';
import { Link } from 'gatsby';

import './availability.scss';
import './header.scss';

const Header = () => (
  <header className="c-header">
    <div className="l-container">
      <div className="c-header__inner">
        <h1 className="c-heading c-heading--h1 u-text--lowercase">
          <Link to="/">
            Ryan Potter.
          </Link>
        </h1>
        <div className="c-availability c-availability--unavailable">
          <span className="c-availability__text">
            Available for contract work:
            {' '}
            <strong>Unavailable</strong>
          </span>
          <span className="c-availability__addon">
            &nbsp;
          </span>
        </div>
      </div>
    </div>
  </header>
);

export default Header;
