import React from 'react';
import PropTypes from 'prop-types';

import './pagination.scss';

/**
 * @param props
 * @return {*}
 * @constructor
 */
const PaginationLink = (props) => {
  const {
    children: CHILDREN,
  } = props;

  return (
    <div className="l-pagination">
      {CHILDREN}
    </div>
  );
};

PaginationLink.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element),
  ]).isRequired,
};

export default PaginationLink;
