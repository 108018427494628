import React from 'react';
import PropTypes from 'prop-types';

import './media.scss';
import { Image } from '../index';

/**
 * @param props
 * @return {*}
 * @constructor
 */
const Media = (props) => {
  const {
    desktop: DESKTOP_IMAGE,
    mobile: MOBILE_IMAGE,
  } = props;

  if (!DESKTOP_IMAGE && !MOBILE_IMAGE) {
    return null;
  }

  return (
    <div className="c-media">
      <div className={`c-media__item c-media__item--large${DESKTOP_IMAGE ? ' c-media__item--background' : ''}`}>
        <div className="c-image c-image--loader c-image--465by277">
          {
            DESKTOP_IMAGE
              ? (
                <Image
                  alt="Desktop thumbnail"
                  draggable={false}
                  className="c-media__image"
                  src={DESKTOP_IMAGE}
                />
              )
              : null}
        </div>
      </div>
      <div className={`c-media__item${MOBILE_IMAGE ? ' c-media__item--background' : ''}`}>
        <div className={`c-image${MOBILE_IMAGE ? ' c-image--loader' : ''} c-image--61by130`}>
          {
            MOBILE_IMAGE
              ? (
                <Image
                  alt="Mobile thumbnail"
                  draggable={false}
                  className="c-media__image"
                  src={MOBILE_IMAGE}
                />

              )
              : null}
        </div>
      </div>
    </div>
  );
};

Media.defaultProps = {
  desktop: null,
  mobile: null,
};

Media.propTypes = {
  desktop: PropTypes.string,
  mobile: PropTypes.string,
};

export default Media;
