import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';
import { TRANSITION_DURATION, TRANSITION_NAME } from '../../config';
import { Images } from '../../utilities';

class Image extends React.Component {
  static defaultProps = {
    alt: null,
    className: null,
    draggable: true,
  };

  static propTypes = {
    alt: PropTypes.string,
    className: PropTypes.string,
    src: PropTypes.string.isRequired,
    draggable: PropTypes.bool,
  };

  constructor(props) {
    super(props);

    this.state = {
      loaded: false,
      src: null,
    };

    this.handleLoadImage = this.handleLoadImage.bind(this);
  }

  // Lifecycle
  // -------------------------------------------

  componentDidMount() {
    this.handleLoadImage();
  }

  // Handlers
  // -------------------------------------------

  /**
   * Set the initial src
   */
  handleLoadImage() {
    const { src: SRC } = this.props;

    this.setState({ loaded: false }, () => Images.fetchImage(SRC).then(() => this.setState({ loaded: true, src: SRC })));
  }

  // Render
  // -------------------------------------------

  render() {
    const {
      alt: ALT,
      className: CLASS_NAME,
      draggable: DRAGGABLE,
    } = this.props;

    const {
      loaded: LOADED,
      src: SRC,
    } = this.state;

    return (
      <CSSTransition
        in={LOADED}
        classNames={{ ...TRANSITION_NAME }}
        unmountOnExit
        timeout={TRANSITION_DURATION}
      >
        <img
          src={SRC}
          className={CLASS_NAME}
          alt={ALT}
          draggable={DRAGGABLE}
        />
      </CSSTransition>
    );
  }
}

export default Image;
