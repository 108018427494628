import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';

import './pagination-link.scss';

/**
 * @param props
 * @return {*}
 * @constructor
 */
const PaginationLink = (props) => {
  const {
    heading: HEADING,
    slug: SLUG,
    title: TITLE,
  } = props;

  if (!SLUG) {
    return (
      <span className="c-pagination-link" />
    );
  }

  return (
    <Link
      className="c-pagination-link"
      to={SLUG}
    >
      <span className="c-pagination-link__heading">{HEADING}</span>
      <span className="c-pagination-link__title">{TITLE}</span>
    </Link>
  );
};

PaginationLink.defaultProps = {
  heading: null,
  slug: null,
  title: null,
};

PaginationLink.propTypes = {
  heading: PropTypes.string,
  slug: PropTypes.string,
  title: PropTypes.string,
};

export default PaginationLink;
