import React from 'react';
import PropTypes from 'prop-types';
import { CSSTransition } from 'react-transition-group';

import { Footer, Header } from '../index';
import { TRANSITION_DURATION, TRANSITION_NAME } from '../../config';

import '../../styles/index.scss';

const Layout = ({ children }) => (
  <main className="c-main">
    <div className="l-full-page">
      <div className="l-full-page__content">
        <Header />
        <CSSTransition
          in
          appear
          timeout={TRANSITION_DURATION.LONG}
          classNames={TRANSITION_NAME}
        >
          <div className="c-page">
            {children}
          </div>
        </CSSTransition>
      </div>
      <Footer />
    </div>
  </main>
);

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
