export const STATES = {
  ACTIVE: 'is-active',
  COLLAPSING: 'is-collapsing',
  COLLAPSED: 'is-collapsed',
  EXPANDING: 'is-expanding',
  EXPANDED: 'is-expanded',
  LOADING: 'is-loading',
  LOADED: 'is-loaded',
  LOCKED: 'is-locked',
  SUBMITTING: 'is-submitting',
  SUBMITTED: 'is-submitted',
  VISIBLE: 'is-visible',
  TABBING: 'is-tabbing',
  PREV: 'is-prev',
  NEXT: 'is-next',
  HAS_MESSAGE: 'has-message',
  SUCCESS: 'is-success',
  ERROR: 'is-error',
  DISABLED: 'is-disabled',
  HIDDEN: 'is-hidden',
  SCROLLED_UP: 'has-scrolled-up',
  SCROLLED_DOWN: 'has-scrolled-down',
};

export const TRANSITION_DURATION = {
  DEFAULT: 200,
  LONG: 400,
};

export const TRANSITION_NAME = {
  appear: STATES.EXPANDING,
  appearActive: STATES.EXPANDED,
  enter: STATES.EXPANDING,
  enterActive: STATES.EXPANDED,
  enterDone: STATES.EXPANDED,
  exit: STATES.COLLAPSING,
  exitActive: STATES.COLLAPSED,
  exitDone: STATES.COLLAPSED,
};
