import React from 'react';
import PropTypes from 'prop-types';

/**
 * @param props
 * @constructor
 */
const Typography = (props) => {
  const {
    content: CONTENT,
  } = props;

  if (!CONTENT) {
    return null;
  }

  const CONTENT_ITEMS = CONTENT.map((node, index) => (
    <p key={index}>{node}</p>
  ));

  return (
    <div className="l-typography">
      {CONTENT_ITEMS}
    </div>
  );
};

Typography.defaultProps = {
  content: [],
};

Typography.propTypes = {
  content: PropTypes.arrayOf(PropTypes.string),
};

export default Typography;
