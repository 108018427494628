import React from 'react';
import { Link } from 'gatsby';

import EnvelopeIcon from '../../svg/envelope.svg';
import GithubIcon from '../../svg/github.svg';
import LinkedInIcon from '../../svg/linkedin.svg';

const Footer = () => (
  <footer className="c-footer">
    <div className="l-container">
      <div className="c-footer__inner">
        <p>
          <strong>Ryan Potter.</strong>
          {' '}
          All rights reserved.
        </p>
        <ul className="l-list">
          <li className="l-list__item">
            <Link to="/cv" className="c-anchor">
              curriculum vitae
            </Link>
          </li>
          <li className="l-list__item">
            <a
              href="mailto:ryanpotter.nz@gmail.com"
              className="c-icon"
              target="_blank"
              rel="noreferrer noopener"
            >
              <EnvelopeIcon />
              <span className="u-sr-only">Ryan Potter's email address.</span>
            </a>
          </li>
          <li className="l-list__item">
            <a
              href="https://github.com/Rhym"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="c-icon">
                <GithubIcon />
                <span className="u-sr-only">Ryan Potter on Github.</span>
              </span>
            </a>
          </li>
          <li className="l-list__item">
            <a
              href="https://www.linkedin.com/in/ryan-potter-bbbb9527/"
              target="_blank"
              rel="noreferrer noopener"
            >
              <span className="c-icon">
                <LinkedInIcon />
                <span className="u-sr-only">Ryan Potter on LinkedIn.</span>
              </span>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </footer>
);

export default Footer;
