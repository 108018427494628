import { STATES } from '../../config';

const Images = {
  /**
   * @desc Get image from url
   * @param url
   */
  fetchImage: url => new Promise((resolve, reject) => {
    const IMAGE = new Image();
    IMAGE.src = url;
    IMAGE.onload = resolve;
    IMAGE.onerror = reject;
  }),

  /**
   * @desc Load an image from it's data attribute.
   * @param image
   */
  loadImageFromData: (image) => {
    if (typeof image === 'undefined' || image === null) return;

    const IMAGE = image;
    const IMAGE_NODE = IMAGE.querySelector('img');

    if (
      typeof IMAGE_NODE === 'undefined'
      || IMAGE_NODE === null
      || typeof IMAGE_NODE.dataset === 'undefined'
      || IMAGE_NODE.dataset === null
      || typeof IMAGE_NODE.dataset.src === 'undefined'
      || IMAGE_NODE.dataset.src === null
    ) return;

    const SRC = IMAGE_NODE.dataset.src;

    Images.fetchImage(SRC)
      .then(() => {
        IMAGE.classList.remove(STATES.LOADING);
        IMAGE.classList.add(STATES.LOADED);
        IMAGE_NODE.src = SRC;
      })
      .catch((error) => {
        console.error(error); // eslint-disable-line no-console
        IMAGE.classList.remove(STATES.LOADING);
        IMAGE.classList.add(STATES.ERROR);
      });
  },
};

export default Images;
